import React, { useEffect, useState } from 'react';
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './CreditRatingContainer.i18n'
import { ArticleArea } from '../../Styling/Layout/ContentArea';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { getAhlinCMSbyId } from '../../reduxStore/HeadlessCMS/headlessCmsActions';

const PrislisteCreditRating = (props) => {

    const locale = React.useContext(LocaleContext);
    const showToasts = true
    const localeMessages = messages[locale]
    const articleId = 'bislab_prisliste'
    const cmsArticlesState = useSelector(state => state.headlessCMS)
    const _dispatch = useDispatch()
    
    useEffect(() => {
      // Ikke hent dersom det er unødvendig, da risikerer du å refreshe token
      // når idle logout modal vises, og auto logoff vil ikke fungere
      let fetchCMS = (!cmsArticlesState.data[articleId])
      if (fetchCMS) {
        _dispatch(getAhlinCMSbyId(articleId, showToasts, localeMessages))
      }
    }, [])
    

    const getInfo = (htmlContent) => {
        if (htmlContent) {
          return (
            <div style={{ backgroundColor: '#fdfbf2', width: '1500px', padding: '20px' }}>
              {htmlContent.map((item) => (
                <div key={item.id}>
                  <h2 dangerouslySetInnerHTML={{ __html: item.title.rendered }}></h2>
                  <div dangerouslySetInnerHTML={{ __html: item.content.rendered }}></div>
                </div>
              ))}
            </div>
          )
        }
        return null;
      }

    return (
        <>
          <ArticleArea>
            {getInfo(cmsArticlesState.data[articleId] || '')}
            <br />
          </ArticleArea>
        </>
      )
}

export default PrislisteCreditRating